// originally from https://codepen.io/brucevang/pen/qZOPwR

 .codalab-item-placeholder {
  height: 100px;
  width: 100%;
//  background: #F6F6F6;
  margin: 5px 0;
  @extend .codalab-animated-background;
 }
 
 // Animation
 @keyframes placeHolderShimmer{
     0%{
         background-position: -468px 0
     }
     100%{
         background-position: 468px 0
     }
 }
 
 .codalab-animated-background {
     animation-duration: 1.25s;
     animation-fill-mode: forwards;
     animation-iteration-count: infinite;
     animation-name: placeHolderShimmer;
     animation-timing-function: linear;
     background: #F6F6F6;
     background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
     background-size: 800px 104px;
     position: relative;
 }
 