#store-content .store-header {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
    margin-bottom: 12px;
    line-height: 1.6;
  }
  #store-content .store-header h2 {
    margin: 0;
    background-position-y: 8px;
  }
  #store-content .store-header .store-name {
    flex: 1;
  }
  #store-content .store-header .store-links .store-download {
    width: 52px;
    height: 45px;
    padding-top: 12px;
    margin-top: 10px;
  }
  #store-content .store-description {
    font-size: 90%;
    margin: 15px 0px 15px 0px;
  }
  #store-content .store-meta {
    margin-bottom: 30px;
  }
  #store-content .store-meta .editable {
    color: brown;
  }
  #store-content .store-meta td {
    width: 50%;
    line-height: 2;
  }
  #store-content h3 {
    margin-top: 20px;
  }
  #store-content h3 .btn {
    margin-left: 6px;
  }
  #store-content table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
    border: 1px solid #ddd;
    margin-bottom: 0;
  }
  #store-content table > thead > tr > th,
  #store-content table > tbody > tr > th,
  #store-content table > tfoot > tr > th,
  #store-content table > thead > tr > td,
  #store-content table > tbody > tr > td,
  #store-content table > tfoot > tr > td {
    padding: 4px 6px;
    line-height: 1.6;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }
  #store-content table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
  }
  #store-content table > caption + thead > tr:first-child > th,
  #store-content table > colgroup + thead > tr:first-child > th,
  #store-content table > thead:first-child > tr:first-child > th,
  #store-content table > caption + thead > tr:first-child > td,
  #store-content table > colgroup + thead > tr:first-child > td,
  #store-content table > thead:first-child > tr:first-child > td {
    border-top: 0;
  }
  #store-content table > tbody + tbody {
    border-top: 2px solid #ddd;
  }
  #store-content table .table {
    background-color: #f7f7f7;
  }
  #store-content table > thead > tr > th,
  #store-content table > tbody > tr > th,
  #store-content table > tfoot > tr > th,
  #store-content table > thead > tr > td,
  #store-content table > tbody > tr > td,
  #store-content table > tfoot > tr > td {
    border: 1px solid #ddd;
  }
  #store-content table > thead > tr > th,
  #store-content table > thead > tr > td {
    border-bottom-width: 2px;
  }
  #store-content table > tbody > tr:nth-child(odd) > td,
  #store-content table > tbody > tr:nth-child(odd) > th {
    background-color: white;
  }
  #store-content .state-table {
    margin-top: 12px;
  }
  @media screen and (max-width: 767px) {
    #store-content .state-table {
      width: 100%;
      margin-bottom: 18.75px;
      overflow-y: hidden;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd;
      -webkit-overflow-scrolling: touch;
    }
    #store-content .state-table > .table {
      margin-bottom: 0;
    }
    #store-content .state-table > .table > thead > tr > th,
    #store-content .state-table > .table > tbody > tr > th,
    #store-content .state-table > .table > tfoot > tr > th,
    #store-content .state-table > .table > thead > tr > td,
    #store-content .state-table > .table > tbody > tr > td,
    #store-content .state-table > .table > tfoot > tr > td {
      white-space: nowrap;
    }
    #store-content .state-table > .table-bordered {
      border: 0;
    }
    #store-content .state-table > .table-bordered > thead > tr > th:first-child,
    #store-content .state-table > .table-bordered > tbody > tr > th:first-child,
    #store-content .state-table > .table-bordered > tfoot > tr > th:first-child,
    #store-content .state-table > .table-bordered > thead > tr > td:first-child,
    #store-content .state-table > .table-bordered > tbody > tr > td:first-child,
    #store-content .state-table > .table-bordered > tfoot > tr > td:first-child {
      border-left: 0;
    }
    #store-content .state-table > .table-bordered > thead > tr > th:last-child,
    #store-content .state-table > .table-bordered > tbody > tr > th:last-child,
    #store-content .state-table > .table-bordered > tfoot > tr > th:last-child,
    #store-content .state-table > .table-bordered > thead > tr > td:last-child,
    #store-content .state-table > .table-bordered > tbody > tr > td:last-child,
    #store-content .state-table > .table-bordered > tfoot > tr > td:last-child {
      border-right: 0;
    }
    #store-content .state-table > .table-bordered > tbody > tr:last-child > th,
    #store-content .state-table > .table-bordered > tfoot > tr:last-child > th,
    #store-content .state-table > .table-bordered > tbody > tr:last-child > td,
    #store-content .state-table > .table-bordered > tfoot > tr:last-child > td {
      border-bottom: 0;
    }
  }
  #store-content .state-table code {
    font-size: 80%;
    color: #666;
    background-color: transparent;
  }

  .store-state {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: #fa963f;
  }
  a.store-state:hover,
  a.store-state:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .store-state:empty {
    display: none;
  }
  .btn .store-state {
    position: relative;
    top: -1px;
  }
  .store-state[href]:hover,
  .store-state[href]:focus {
    background-color: #f97b0d;
  }
  .store-state.state-ready {
    background-color: #92D053;
  }
  .store-state.state-ready[href]:hover,
  .store-state.state-ready[href]:focus {
    background-color: #79bc34;
  }
  .store-state.state-failed {
    background-color: #d9534f;
  }
  .store-state.state-failed[href]:hover,
  .store-state.state-failed[href]:focus {
    background-color: #c9302c;
  }