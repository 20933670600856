.file-browser-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
    margin-bottom: 0;
  }
  .file-browser-table > thead > tr > th,
  .file-browser-table > tbody > tr > th,
  .file-browser-table > tfoot > tr > th,
  .file-browser-table > thead > tr > td,
  .file-browser-table > tbody > tr > td,
  .file-browser-table > tfoot > tr > td {
    padding: 4px 6px;
    line-height: 1.6;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }
  .file-browser-table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
  }
  .file-browser-table > caption + thead > tr:first-child > th,
  .file-browser-table > colgroup + thead > tr:first-child > th,
  .file-browser-table > thead:first-child > tr:first-child > th,
  .file-browser-table > caption + thead > tr:first-child > td,
  .file-browser-table > colgroup + thead > tr:first-child > td,
  .file-browser-table > thead:first-child > tr:first-child > td {
    border-top: 0;
  }
  .file-browser-table > tbody + tbody {
    border-top: 2px solid #ddd;
  }
  .file-browser-table .table {
    background-color: #f7f7f7;
  }
  .file-browser-table > tbody > tr:nth-child(odd) > td,
  .file-browser-table > tbody > tr:nth-child(odd) > th {
    background-color: white;
  }
  .file-browser-table > thead > tr > th,
  .file-browser-table > tbody > tr > th,
  .file-browser-table > tfoot > tr > th,
  .file-browser-table > thead > tr > td,
  .file-browser-table > tbody > tr > td,
  .file-browser-table > tfoot > tr > td {
    line-height: 1.2;
    font-size: 14px;
    border-width: 0;
  }
  .file-browser-table > thead > tr > th a,
  .file-browser-table > tbody > tr > th a,
  .file-browser-table > tfoot > tr > th a,
  .file-browser-table > thead > tr > td a,
  .file-browser-table > tbody > tr > td a,
  .file-browser-table > tfoot > tr > td a,
  .file-browser-table > thead > tr > th span,
  .file-browser-table > tbody > tr > th span,
  .file-browser-table > tfoot > tr > th span,
  .file-browser-table > thead > tr > td span,
  .file-browser-table > tbody > tr > td span,
  .file-browser-table > tfoot > tr > td span {
    cursor: pointer;
  }
  .file-browser-table > thead > tr > th .directory,
  .file-browser-table > tbody > tr > th .directory,
  .file-browser-table > tfoot > tr > th .directory,
  .file-browser-table > thead > tr > td .directory,
  .file-browser-table > tbody > tr > td .directory,
  .file-browser-table > tfoot > tr > td .directory {
    font-weight: 500;
  }
  .file-browser-table > thead > tr > th .file,
  .file-browser-table > tbody > tr > th .file,
  .file-browser-table > tfoot > tr > th .file,
  .file-browser-table > thead > tr > td .file,
  .file-browser-table > tbody > tr > td .file,
  .file-browser-table > tfoot > tr > td .file {
    font-weight: normal;
  }
  .file-browser-table > thead > tr > th .file .size,
  .file-browser-table > tbody > tr > th .file .size,
  .file-browser-table > tfoot > tr > th .file .size,
  .file-browser-table > thead > tr > td .file .size,
  .file-browser-table > tbody > tr > td .file .size,
  .file-browser-table > tfoot > tr > td .file .size {
    width: 5em;
    float: right;
    font-family: 'Roboto Mono', monospace;
    font-size: 80%;
    line-height: 1.6;
  }
  .file-browser-table .glyphicon {
    font-size: 14px;
    color: #225ea8;
    margin-right: 6px;
  }
  .file-browser-table .glyphicon.glyphicon-file {
    color: #999;
  }
  .file-browser-arrow {
    cursor: pointer;
  }
  .file-browser .panel-heading span {
    cursor: pointer;
    color: #225ea8;
  }