html {
    font-size: 16px;
}
body {
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: normal;
    line-height: 1.6;
    font-size: 16px;
    color: #666;
    background-color: #f7f7f7;
}
th {
    font-weight: 500;
}
b {
    font-weight: 500;
}
footer {
    background-color: #225ea8;
}

/*TODO: remove this when we replace jquery-terminal*/
iframe {
    border: 0;
}

.modal {
    top: 64px;
    z-index: 1070;
}

#codalab-search-bar,
.ui.search > .results .result .title,
.ui.search > .results > .message .header,
.ui.category.search > .results .category > .name {
    font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
}

// override ansi-to-react styles
code {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    background: transparent;
    padding: 0;
    border-radius: 0;
}
